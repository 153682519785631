import React from "react";

const Error = () => {
  return (
    <div>
      <h1>404 Page Not Found! :(</h1>
    </div>
  );
};

export default Error;
